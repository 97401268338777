import axios from "axios";

const axiosInstance = axios.create({
    headers: {
        "Content-Type": "application/json"
    }
});

const requestGet = (path, config = undefined) =>
    axiosInstance
        .get(path, config)
        .then((response) => response)
        .catch((error) => Promise.reject(error.response));

const requestPost = (path, json, config = undefined) =>
    axiosInstance
        .post(path, json, config)
        .then((response) => response)
        .catch((error) => Promise.reject(error.response));

const loginParms = (user, pass, rememberMe) => {
    const params = new URLSearchParams();

    params.append("username", user);
    params.append("password", pass);
    params.append("rememberMe", rememberMe);

    return params;
};

// Login Requests

// Verify username and password.
const login = (user, pass, rememberMe) =>
    requestPost("/login", loginParms(user, pass, rememberMe), {
        headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" }
    })
        .then((response) => response)
        .catch((error) => Promise.reject(error));

// Session check requests (on timer)
const session = () => requestGet("/session");

// Logout Request
const logout = () => requestPost("/logout");

// Registration Requests

// Step 1 -- Verify the user
const verifyRegistration = (code) => requestGet(`/code/${code}`);

// Step 2 - User finishes registration form
const submitRegistration = (form) => requestPost("/registration", form);

// Forgot Password Requests

// Step 1 -- Send forgot password request
const submitReset = (form) => requestPost("/reset", form);

// Step 2 -- Verify the token emailed to user
const verifyReset = (token) => requestGet(`/reset/verify/${token}`);

// Step 3 - User finishes change password form
const updateReset = (form) => requestPost("/reset/update", form);

const getDashboard = () => requestGet("/subscription/dashboard");

// Payment
const getPlan = () => requestGet("/subscription/plan");
const getPlans = () => requestGet("/subscription/plans");
const submitPayment = (data) => requestPost("/subscription/charge", data);
const cancelPlan = () => requestGet("/subscription/cancel");

const getDevices = () => requestGet("/api/device/list/all");
const addDevice = (code) => requestGet(`/api/device/link/${code}`);

export default {
    session,

    login,
    logout,

    verifyRegistration,
    submitRegistration,

    submitReset,
    verifyReset,
    updateReset,

    getDashboard,

    getPlan,
    getPlans,
    submitPayment,
    cancelPlan,

    getDevices,
    addDevice
};
