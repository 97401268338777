import React from "react";
import { connect } from "react-redux";
// import { ToastContainer } from "react-toastify";
import { hot } from "react-hot-loader";
import { onLoad, onUnload } from "../actions/pages";

import AppRouter from "../routers/AppRouter";
import LoadingPage from "./LoadingPage";

import { pageTitle } from "../helpers/utils";
import { verifyAccount } from "../actions/auth";

class App extends React.Component {
    componentDidMount = () => {
        this.props.onLoad();

        document.title = pageTitle();

        // call server and see if user is logged in
        this.props.verifyAccount();

        setInterval(this.sessionCheck.bind(this), 300000);
    };

    componentWillUnmount = () => {
        this.props.onUnload();
    };

    sessionCheck = () => {
        this.props.verifyAccount();
    };

    render() {
        return this.props.signedIn !== undefined ? <AppRouter /> : <LoadingPage />;
    }
}

const mapStateToProps = (state) => ({
    signedIn: state.auth.signedIn
});

const mapDispatchToProps = (dispatch, props) => ({
    verifyAccount: () => dispatch(verifyAccount()),

    onLoad: () => dispatch(onLoad("APP")),
    onUnload: () => dispatch(onUnload("APP"))
});

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(App));
